@tailwind base;
@tailwind components;
@tailwind utilities;

/* Dark mode styles */
.ql-editor { 
  @apply bg-card
}

.dark .ql-toolbar {
  @apply bg-card-dark text-text-dark;
}

.dark .ql-editor {
  @apply bg-background-dark text-white;
}

.dark .ql-container {
  @apply border-card-dark text-text-dark;
}
