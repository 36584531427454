@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

.task-list { 
    @apply bg-black w-full py-2 cursor-pointer;
}

.review-content-section { 
    @apply border rounded-lg;
}

.review-table-container .headers { 
    @apply flex flex-nowrap justify-evenly overflow-hidden bg-gray-200 rounded-t-lg;
}

.col-header { 
    @apply truncate font-bold text-[12px] w-full p-1;
}

.table-data { 
    @apply flex flex-nowrap justify-evenly overflow-hidden p-1 cursor-pointer;
}

.col-data { 
    @apply truncate text-[12px] w-full
}

.table-data:hover { 
    @apply bg-gray-100;
}

.no-select { 
    @apply pointer-events-none cursor-move;
}

.selected-user { 
    @apply border-[#3DD598] ;
}

.non-selected-user { 
    @apply border-gray-300;
}